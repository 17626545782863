import {storeToRefs} from "pinia";
import {useUserStore} from "~~/stores/user";

export default defineNuxtRouteMiddleware(async (to, from) => {
    const userStore = useUserStore();
    const {user} = storeToRefs(userStore);

    try {
        // console.log("logged out middleware");
        await userStore.getUser();
        if (!user.value.isProfileSetup) return navigateTo('/setup-profile')
        else return true

    } catch (error) {
        return navigateTo("/login");
    }
});
